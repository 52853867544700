var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    staticClass: "container",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "column is-half is-offset-one-third"
        }, [_c('div', {
          staticClass: "box is-primary"
        }, [_c('div', {
          staticClass: "title has-text-grey"
        }, [_c('div', {
          staticClass: "block mt-6"
        }, [_c('v-icon', {
          staticClass: "is-primary",
          attrs: {
            "icon": "user"
          }
        })], 1), _c('div', {
          staticClass: "block"
        }, [_c('h1', [_vm._v("Login")])])]), _c('div', {
          staticClass: "block m-6"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "user"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors,
                  valid = _ref2.valid;
              return _c('b-field', {
                attrs: {
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "User",
                  "icon": "user",
                  "icon-right": "close-circle",
                  "icon-right-clickable": "",
                  "placeholder": "Usuario"
                },
                on: {
                  "icon-right-click": _vm.clearIconClick
                },
                nativeOn: {
                  "keyup": function keyup($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.validateLogin.apply(null, arguments);
                  }
                },
                model: {
                  value: _vm.loginForm.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.loginForm, "username", $$v);
                  },
                  expression: "loginForm.username"
                }
              })], 1);
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors,
                  valid = _ref3.valid;
              return _c('b-field', {
                attrs: {
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Password",
                  "type": "password",
                  "icon": "key",
                  "password-reveal": "",
                  "placeholder": "Contraseña"
                },
                nativeOn: {
                  "keyup": function keyup($event) {
                    if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
                    return _vm.validateLogin.apply(null, arguments);
                  }
                },
                model: {
                  value: _vm.loginForm.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.loginForm, "password", $$v);
                  },
                  expression: "loginForm.password"
                }
              })], 1);
            }
          }], null, true)
        }), _c('b-button', {
          staticClass: "button is-primary mt-4",
          attrs: {
            "rounded": ""
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.login);
            }
          }
        }, [_c('span', [_vm._v("Entrar")])])], 1)])])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }