var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "observer",
    staticClass: "container",
    attrs: {
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit;
        return [_c('div', {
          staticClass: "column is-half is-offset-one-third"
        }, [_c('div', {
          staticClass: "box is-primary"
        }, [_c('div', {
          staticClass: "title has-text-grey"
        }, [_c('div', {
          staticClass: "block mt-6"
        }, [_c('v-icon', {
          staticClass: "is-primary",
          attrs: {
            "icon": "user"
          }
        })], 1), _c('div', {
          staticClass: "block"
        }, [_c('h1', [_vm._v("Resetear Contraseña")])]), _c('div', {
          staticClass: "block"
        }, [_c('p', {
          staticClass: "is-size-6 has-text-justified has-text-weight-light"
        }, [_vm._v("La contraseña con la que ha intentado acceder a la aplicación es una contraseña temporal creada por un administrador. Por motivos de seguridad, se requiere que la cambie por una de su elección. La contraseña que elija debe tener al menos 6 caracteres de longitud. Se recomienda el uso de mayúsculas, minúsculas, números y símbolos.")])])]), _c('div', {
          staticClass: "block m-6"
        }, [_c('validation-provider', {
          attrs: {
            "rules": "required",
            "name": "temporary_password"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors,
                  valid = _ref2.valid;
              return _c('b-field', {
                attrs: {
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Temporary Password",
                  "type": "password",
                  "icon": "password",
                  "password-reveal": "",
                  "placeholder": "Contraseña Temporal",
                  "disabled": ""
                },
                model: {
                  value: _vm.resetPasswordForm.temporaryPassword,
                  callback: function callback($$v) {
                    _vm.$set(_vm.resetPasswordForm, "temporaryPassword", $$v);
                  },
                  expression: "resetPasswordForm.temporaryPassword"
                }
              })], 1);
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "vid": "newpassword",
            "rules": "required|min:6",
            "name": "newPassword"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref3) {
              var errors = _ref3.errors,
                  valid = _ref3.valid;
              return _c('b-field', {
                attrs: {
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Password",
                  "type": "password",
                  "icon": "key",
                  "password-reveal": "",
                  "placeholder": "Nueva Contraseña"
                },
                model: {
                  value: _vm.resetPasswordForm.password,
                  callback: function callback($$v) {
                    _vm.$set(_vm.resetPasswordForm, "password", $$v);
                  },
                  expression: "resetPasswordForm.password"
                }
              })], 1);
            }
          }], null, true)
        }), _c('validation-provider', {
          attrs: {
            "rules": "required|confirmed:newpassword",
            "name": "confirmPassword"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors,
                  valid = _ref4.valid;
              return _c('b-field', {
                attrs: {
                  "type": {
                    'is-danger': errors[0],
                    'is-success': valid
                  },
                  "message": errors
                }
              }, [_c('b-input', {
                attrs: {
                  "value": "Confirm Password",
                  "type": "password",
                  "icon": "key",
                  "password-reveal": "",
                  "placeholder": "Confirmar Contraseña"
                },
                model: {
                  value: _vm.resetPasswordForm.confirmPassword,
                  callback: function callback($$v) {
                    _vm.$set(_vm.resetPasswordForm, "confirmPassword", $$v);
                  },
                  expression: "resetPasswordForm.confirmPassword"
                }
              })], 1);
            }
          }], null, true)
        }), _c('b-button', {
          staticClass: "button is-primary mt-4",
          attrs: {
            "disabled": _vm.resetingPassword,
            "rounded": ""
          },
          on: {
            "click": function click($event) {
              return handleSubmit(_vm.resetPassword);
            }
          }
        }, [_c('span', [_vm._v("Resetear Contraseña")])])], 1)])])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }