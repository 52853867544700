import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from '@/store/store'
import VueResource from 'vue-resource'

import axios from 'axios'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// Lodash
import VueLodash from 'vue-lodash'
import cloneDeep from 'lodash/cloneDeep'
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
// import { far } from '@fortawesome/free-regular-svg-icons'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './validation/validationRules'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// Buefy
import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
import { i18n } from './locales/i18n'

Vue.use(Vuex)
Vue.use(VueLodash, { lodash: { cloneDeep } })

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.component('v-icon', FontAwesomeIcon)
library.add(fas)

Vue.use(ElementUI, {
  locale: 'es',
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(Buefy, {
  defaultIconComponent: 'v-icon',
  defaultIconPack: 'fas'
})

Vue.use(VueResource)

Vue.config.productionTip = false
Vue.prototype.axios = axios

new Vue({
  el: '#app',
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
