var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "hero is-bg-gray is-fullheight"
  }, [_vm._m(0), _c('div', {
    staticClass: "hero-body pb-0 columns is-multiline"
  }, [_vm._m(1), _c('div', {
    staticClass: "column is-half"
  }, [!_vm.resetPasswordRequired ? _c('sign-in', {
    on: {
      "resetPasswordRequired": _vm.handleResetPasswordRequired
    }
  }) : _c('reset-password', {
    attrs: {
      "temporary-password": _vm.temporaryPassword,
      "username": _vm.username
    },
    on: {
      "passwordReset": _vm.handlePasswordReset
    }
  })], 1)]), _c('div', {
    staticClass: "hero-foot pb-6 mt-0"
  }, [_c('div', {
    staticClass: "is-flex container is-fluid is-justify-content-space-between is-align-items-center"
  }, [_c('div', {
    staticClass: "is-flex is-flex-direction-column"
  }, [_c('div', {
    staticClass: "highlighted is-flex is-justify-content-center"
  }, [_c('b-image', {
    attrs: {
      "src": "https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/fundacion_inserta_rgb_300p.jpg"
    }
  })], 1), _c('div', {
    staticClass: "logos is-flex is-justify-content-space-between"
  }, [_c('b-image', {
    attrs: {
      "src": "https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE+Empleo+Juvenil.jpg",
      "alt": "FSE Empleo Juvenil"
    }
  }), _c('b-image', {
    attrs: {
      "src": "https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE.jpg",
      "alt": "FSE"
    }
  })], 1)]), _vm._m(2)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-head"
  }, [_c('div', {
    staticClass: "navbar"
  }, [_c('div', {
    staticClass: "container"
  })])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "column is-half has-text-left"
  }, [_c('h3', {
    staticClass: "is-title has-text-primary has-text-weight-bold"
  }, [_vm._v("Bienvenido/a al cuestionario de análisis del puesto de trabajo (APT).")]), _c('h3', {
    staticClass: "my-3"
  }, [_vm._v("INTRODUCCIÓN")]), _c('p', [_vm._v("El objetivo de este cuestionario es describir con el mayor detalle posible el puesto de trabajo que desea cubrir para facilitar a los técnicos de Inserta la búsqueda de la persona adecuada. ")]), _c('h3', {
    staticClass: "my-3"
  }, [_vm._v("¿QUE ES UN ANÁLISIS DEL PUESTO DE TRABAJO (APT)?")]), _c('p', [_vm._v(" Una herramienta que recoge con detalle el cometido de un puesto de trabajo, las funciones, responsabilidades y tareas, asi como los conocimientos, habilidades y competencias debe tener la persona que lo ocupe para un desempeño eficaz. ")]), _c('h3', {
    staticClass: "my-3"
  }, [_vm._v(" ¿QUE DEBE TENER EN CUENTA AL CUMPLIMENTAR EL CUESTIONARIO?")]), _c('ol', {
    staticClass: "pl-4"
  }, [_c('li', [_vm._v("Antes de comenzar a cumplimentarlo le sugerimos descargar y leer el lista de comprobación, lo que le permitira tener una visión de conjunto y apreciar las in terrelaciones entre los diferentes apartados. ")]), _c('li', [_vm._v("Al cumplimentarlo, incluya todas las actividades que se deben realizar a lo largo de un año completo, para evitar olvidar actividades o responsabilidades que únicamente se desarrollan en momentos concretos, o que se repiten en cicios de trabajo largos. ")]), _c('li', [_vm._v("En cada sección encontrara apartados de ayuda que contiene explicaciones y diferentes ejemplos que le facilitarán cumplimentar el cuestionario. ")]), _c('li', [_vm._v("Hay casillas con desplegables, solo debe clicar en ellas para encontrar la información que debe incluir. ")])]), _c('div', {
    staticClass: "has-text-centered mt-5"
  }, [_c('a', {
    staticClass: "has-text-primary has-text-weight-bold",
    attrs: {
      "href": "https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/documentos/disclaimer.pdf",
      "target": "_blank"
    }
  }, [_vm._v("Descargar pdf")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "has-text-weight-bold has-text-primary"
  }, [_vm._v("powered by Gesempor")])]);
}]

export { render, staticRenderFns }