import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from '@/router/routes'
import store from '@/store/store'
import * as types from '@/store/types'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters[types.GET_LOGGED]) {
    this.$root.$emit('logout')
  } else {
    next()
  }
})

export default router
