<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item class="brand" tag="router-link" :to="{ path: '/' }">
        <b-image
          src="https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/fundacion_inserta_rgb_300p.jpg" />
      </b-navbar-item>
    </template>

    <template #end>
      <b-navbar-item tag="div">
        <el-row>
          <el-button type="info" icon="el-icon-user" circle></el-button>
          <el-button type="danger" icon="el-icon-switch-button" circle @click="logout()"></el-button>
        </el-row>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import * as types from '@/store/types'
export default {
  name: 'Header',
  methods: {
    logout () {
      this.$store.dispatch(types.LOG_OUT)
      this.$buefy.toast.open({
        duration: 3000,
        message: 'Se ha cerrado la sesión correctamente',
        position: 'is-top',
        type: 'is-success'
      })
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>

<style lang="scss" scoped>

.brand {
  width: 200px;
  padding: 0 !important;
  margin: 0!important;
   & a {
     padding: 0 !important;
     margin: 0!important;
   }
  & .b-image-wrapper, .image {
    width: 100% !important;
    min-width: 100% !important;
  }
  ::v-deep img{
    width: 90%;
    max-height: 3rem;
    object-fit: cover;
  }
}

</style>
