
// Loign
export const LOG_IN = 'login/login'
export const LOG_OUT = 'login/logout'
export const GET_LOGGED = 'login/login'

// User
export const GET_USER = 'user/get'
export const SET_USER = 'user/set'

// Token
export const SET_AUTH = 'auth/set'
export const GET_AUTH = 'auth/get'

// ROLE
export const GET_ROLE = 'auth/get/role'
export const HAS_ROLE_ADMIN = 'role/isAdmin'
export const HAS_ROLE_SUPERADMIN = 'role/isSuperAdmin'
export const HAS_ROLE_ANNALIST = 'role/isAnnalist'
export const HAS_ROLE_BELOW_ANNALIST = 'role/isBelowAnnalist'
