var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-menu', {
    staticClass: "el-menu-vertical is-flex is-flex-direction-column",
    attrs: {
      "router": true,
      "default-active": _vm.activeRoute,
      "background-color": 'rgb(255,255,255)',
      "text-color": '#2d2d2d',
      "active-text-color": '#E32427',
      "collapse": _vm.isCollapse
    },
    on: {
      "open": _vm.handleOpen,
      "close": _vm.handleClose
    }
  }, [_c('div', {
    staticClass: "logos mx-1 mb-1 is-flex is-justify-content-space-between"
  }, [_c('b-image', {
    attrs: {
      "src": "https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE+Empleo+Juvenil.jpg",
      "alt": "FSE Empleo Juvenil"
    }
  }), _c('b-image', {
    attrs: {
      "src": "https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE.jpg",
      "alt": "FSE"
    }
  })], 1), _vm.hasRoleAdmin ? _c('el-submenu', {
    attrs: {
      "index": "Questionnaires"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "question-circle"
    }
  }), _c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Repositorio")])], 1), _c('el-menu-item-group', [_c('el-menu-item', {
    attrs: {
      "index": "Question",
      "route": {
        name: 'Question'
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "eye"
    }
  }), _vm._v(" Preguntas ")], 1), _c('el-menu-item', {
    attrs: {
      "index": "Category",
      "route": {
        name: 'Category'
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "folder"
    }
  }), _vm._v(" Categorías ")], 1)], 1)], 2) : _vm._e(), _vm.hasRoleAdmin ? _c('el-menu-item', {
    attrs: {
      "index": "Questionnaire",
      "route": {
        name: 'Questionnaire'
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "clipboard-list"
    }
  }), _c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Fichas de análisis")])], 1) : _vm._e(), _vm.hasRoleAdmin ? _c('el-menu-item', {
    attrs: {
      "index": "Company",
      "route": {
        name: 'Company'
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "briefcase"
    }
  }), _c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Empresas")])], 1) : _vm._e(), _vm.hasRoleAdmin ? _c('el-menu-item', {
    attrs: {
      "index": "Users",
      "route": {
        name: 'User'
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "users"
    }
  }), _c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("Usuarios")])], 1) : _vm._e(), _vm.hasRoleBelowAnnalist ? _c('el-submenu', {
    attrs: {
      "index": "DPT"
    }
  }, [_c('template', {
    slot: "title"
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "globe"
    }
  }), _c('span', {
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("APT")])], 1), _c('el-menu-item-group', [_vm.hasRoleBelowAnnalist ? _c('el-menu-item', {
    attrs: {
      "index": "DptConsulting",
      "route": {
        name: 'DptConsulting'
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "star"
    }
  }), _vm._v("Mi ficha de análisis")], 1) : _vm._e(), _vm.hasRoleAnnalist ? _c('el-menu-item', {
    attrs: {
      "index": "DptDashboard",
      "route": {
        name: 'DptDashboard'
      }
    }
  }, [_c('v-icon', {
    staticClass: "g-icon",
    attrs: {
      "icon": "chart-line"
    }
  }), _vm._v("Consultoría APT")], 1) : _vm._e()], 1)], 2) : _vm._e(), _c('el-menu-item', {
    on: {
      "click": function click($event) {
        _vm.isCollapse = !_vm.isCollapse;
      }
    }
  }, [_c('i', {
    class: _vm.isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'
  }), _c('span', {
    staticClass: "has-text-grey",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.isCollapse ? 'Maximizar' : 'Minimizar'))])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }