<template>

  <el-menu :router="true" :default-active="activeRoute" class="el-menu-vertical is-flex is-flex-direction-column" @open="handleOpen"
           @close="handleClose"
           :background-color="'rgb(255,255,255)'"
           :text-color="'#2d2d2d'"
           :active-text-color="'#E32427'"
           :collapse="isCollapse" >
    <div class="logos mx-1 mb-1 is-flex is-justify-content-space-between">
      <b-image
        src="https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE+Empleo+Juvenil.jpg"
        alt="FSE Empleo Juvenil"></b-image>
      <b-image
        src="https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE.jpg"
        alt="FSE"></b-image>
    </div>
    <el-submenu v-if="hasRoleAdmin" index="Questionnaires">
      <template slot="title">
        <v-icon icon="question-circle" class="g-icon"></v-icon>
        <span slot="title">Repositorio</span>
      </template>
      <el-menu-item-group>
        <el-menu-item index="Question" :route="{name:'Question'}">
          <v-icon icon="eye" class="g-icon"></v-icon>
          Preguntas
        </el-menu-item>
        <el-menu-item index="Category" :route="{name:'Category'}">
          <v-icon icon="folder" class="g-icon"></v-icon>
          Categorías
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item v-if="hasRoleAdmin" index="Questionnaire" :route="{name:'Questionnaire'}">
      <v-icon icon="clipboard-list" class="g-icon"></v-icon>
      <span slot="title">Fichas de análisis</span>
    </el-menu-item>
    <el-menu-item v-if="hasRoleAdmin" index="Company" :route="{name:'Company'}">
      <v-icon icon="briefcase" class="g-icon"></v-icon>
      <span slot="title">Empresas</span>
    </el-menu-item>
    <el-menu-item v-if="hasRoleAdmin" index="Users" :route="{name:'User'}">
      <v-icon icon="users" class="g-icon"></v-icon>
      <span slot="title">Usuarios</span>
    </el-menu-item>
    <el-submenu index="DPT" v-if="hasRoleBelowAnnalist">
      <template slot="title">
        <v-icon icon="globe" class="g-icon"></v-icon>
        <span slot="title">APT</span>
      </template>
      <el-menu-item-group>
        <el-menu-item v-if="hasRoleBelowAnnalist" index="DptConsulting" :route="{name:'DptConsulting'}"><v-icon
          icon="star"
                                                                                     class="g-icon"></v-icon>Mi ficha de análisis</el-menu-item>
        <el-menu-item v-if="hasRoleAnnalist" index="DptDashboard" :route="{name:'DptDashboard'}"><v-icon
          icon="chart-line"
                                                                                   class="g-icon"></v-icon>Consultoría APT</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item @click="isCollapse = !isCollapse">
      <i :class="isCollapse ? 'el-icon-arrow-right' :'el-icon-arrow-left'"></i>
      <span slot="title" class="has-text-grey">{{ isCollapse ? 'Maximizar' : 'Minimizar' }}</span>
    </el-menu-item>
  </el-menu>

</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '@/store/types'
export default {
  name: 'Aside',
  computed: {
    ...mapGetters({
      userRole: types.GET_ROLE,
      hasRoleAdmin: types.HAS_ROLE_ADMIN,
      hasRoleSuperAdmin: types.HAS_ROLE_SUPERADMIN,
      hasRoleBelowAnnalist: types.HAS_ROLE_BELOW_ANNALIST,
      hasRoleAnnalist: types.HAS_ROLE_ANNALIST
    })
  },
  watch: {
    $route: function (route) {
      this.activeRoute = route.name
    }
  },
  data () {
    return {
      activeRoute: '',
      isCollapse: false
    }
  },
  methods: {
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-menu-vertical {
  height: 100%;

  &:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;

  }
}

.g-icon {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
}
.logos {

}

</style>
