<template>
  <section class="hero is-bg-gray is-fullheight">
    <div class="hero-head">
      <div class="navbar">
        <div class="container">
        </div>
      </div>
    </div>
    <div class="hero-body pb-0 columns is-multiline">
      <div class="column is-half has-text-left">
        <h3 class="is-title has-text-primary has-text-weight-bold">Bienvenido/a al cuestionario de análisis del puesto
          de
          trabajo
          (APT).</h3>
        <h3 class="my-3">INTRODUCCIÓN</h3>
        <p>El objetivo de este cuestionario es describir con el mayor detalle posible el
          puesto de trabajo que desea cubrir para facilitar a los técnicos de Inserta la
          búsqueda de la persona adecuada.
        </p>
        <h3 class="my-3">¿QUE ES UN ANÁLISIS DEL PUESTO DE TRABAJO (APT)?</h3>
        <p>
          Una herramienta que recoge con detalle el cometido de un puesto de trabajo, las
          funciones, responsabilidades y tareas, asi como los conocimientos, habilidades y
          competencias debe tener la persona que lo ocupe para un desempeño eficaz.
        </p>
        <h3 class="my-3">
          ¿QUE DEBE TENER EN CUENTA AL CUMPLIMENTAR EL CUESTIONARIO?</h3>
        <ol class="pl-4">
          <li>Antes de comenzar a cumplimentarlo le sugerimos descargar y leer el lista de
            comprobación, lo que le permitira tener una visión de conjunto y apreciar las in
            terrelaciones entre los diferentes apartados.
          </li>
          <li>Al cumplimentarlo, incluya todas las actividades que se deben realizar a lo
            largo de un año completo, para evitar olvidar actividades o responsabilidades
            que únicamente se desarrollan en momentos concretos, o que se repiten en
            cicios de trabajo largos.
          </li>
          <li>En cada sección encontrara apartados de ayuda que contiene explicaciones y
            diferentes ejemplos que le facilitarán cumplimentar el cuestionario.
          </li>
          <li>Hay casillas con desplegables, solo debe clicar en ellas para encontrar la información que debe incluir.
          </li>
        </ol>
        <div class="has-text-centered mt-5">
          <a href="https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/documentos/disclaimer.pdf" target="_blank"
             class="has-text-primary has-text-weight-bold">Descargar pdf</a>
        </div>
      </div>
      <div class="column is-half">
        <sign-in v-if="!resetPasswordRequired"
                 @resetPasswordRequired="handleResetPasswordRequired"></sign-in>
        <reset-password :temporary-password="temporaryPassword" :username="username"
                        @passwordReset="handlePasswordReset"
                        v-else></reset-password>
      </div>
    </div>
    <div class="hero-foot pb-6 mt-0">
      <div class="is-flex container is-fluid is-justify-content-space-between is-align-items-center">
        <div class="is-flex is-flex-direction-column">
          <div class="highlighted is-flex is-justify-content-center">
            <b-image
              src="https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/fundacion_inserta_rgb_300p.jpg"/>
          </div>
          <div class="logos is-flex is-justify-content-space-between">
            <b-image
              src="https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE+Empleo+Juvenil.jpg"
              alt="FSE Empleo Juvenil"></b-image>
            <b-image
              src="https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/Logotipo+FSE.jpg"
              alt="FSE"></b-image>
          </div>
        </div>
        <div>
          <h3 class="has-text-weight-bold has-text-primary">powered by Gesempor</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import * as types from '@/store/types'
import SignIn from '@/components/login/SignIn'
import ResetPassword from '@/components/login/ResetPassword'

export default {
  name: 'Login',
  components: {
    SignIn,
    ResetPassword
  },
  data () {
    return {
      resetPasswordRequired: false,
      temporaryPassword: null,
      username: null
    }
  },
  methods: {
    clearIconClick () {
      this.loginForm.user = ''
      // TODO Posible notificación
    },
    handlePasswordReset () {
      this.resetPasswordRequired = false
      this.temporaryPassword = null
      this.username = null
    },
    handleResetPasswordRequired (userData) {
      this.temporaryPassword = userData.temporaryPassword
      this.username = userData.username
      this.resetPasswordRequired = true
    }
  }
}
</script>

<style lang="scss" scoped>
$logos-width: 8rem;
.logos {
  background: white;

  & .b-image-wrapper, .image {
    width: 50% !important;
    min-width: 50% !important;
  }

  & ::v-deep img {
    min-height: 2.5rem !important;
    width: $logos-width !important;
    object-fit: contain;
  }
}

.highlighted {
  background: white !important;
  width: 2 * $logos-width !important;

  & .b-image-wrapper, .image {
    width: 100% !important;
    min-width: 100% !important;

    & ::v-deep img {
      width: 90%;
      max-height: 5rem;
      object-fit: cover;
    }
  }

}

</style>
