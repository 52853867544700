import store from '@/store/store'
import * as types from '@/store/types'

const Category = () => import(/* webpackChunkName: "Category" */ '../views/Category.vue')
const Company = () => import(/* webpackChunkName: "Company" */ '../views/Company.vue')
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
const DptConsulting = () => import(/* webpackChunkName: "dptConsulting" */ '../views/DptConsulting.vue')
const DptDashboard = () => import(/* webpackChunkName: "dpt" */ '../views/DptDashboard.vue')
const Question = () => import(/* webpackChunkName: "Question" */ '../views/Question.vue')
const Questionnaire = () => import(/* webpackChunkName: "Questionnaire" */ '../views/Questionnaire.vue')
const User = () => import(/* webpackChunkName: "User" */ '../views/User.vue')
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ '../components/login/ResetPassword')

export const routes = [
  {
    path: '/',
    redirect: () => {
      if (store.getters[types.HAS_ROLE_ADMIN]) {
        return { name: 'Questionnaire' }
      } else if (store.getters[types.HAS_ROLE_ANNALIST]) {
        return { name: 'DptDashboard' }
      } else {
        return { name: 'DptConsulting' }
      }
    }
  },
  {
    path: '/categorias',
    name: 'Category',
    component: Category
  },
  {
    path: '/empresas',
    name: 'Company',
    component: Company
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/dptconsulting',
    name: 'DptConsulting',
    component: DptConsulting
  },
  {
    path: '/dptdashboard',
    name: 'DptDashboard',
    component: DptDashboard
  },
  {
    path: '/repositorio-de-preguntas',
    name: 'Question',
    component: Question
  },
  {
    path: '/cuestionarios',
    name: 'Questionnaire',
    component: Questionnaire
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    props: true,
    component: ResetPassword
  },
  {
    path: '/user',
    name: 'User',
    component: User
  }
]
