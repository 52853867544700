var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-navbar', {
    scopedSlots: _vm._u([{
      key: "brand",
      fn: function fn() {
        return [_c('b-navbar-item', {
          staticClass: "brand",
          attrs: {
            "tag": "router-link",
            "to": {
              path: '/'
            }
          }
        }, [_c('b-image', {
          attrs: {
            "src": "https://s3.eu-west-3.amazonaws.com/inserta.gesempor.es/logos/fundacion_inserta_rgb_300p.jpg"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "end",
      fn: function fn() {
        return [_c('b-navbar-item', {
          attrs: {
            "tag": "div"
          }
        }, [_c('el-row', [_c('el-button', {
          attrs: {
            "type": "info",
            "icon": "el-icon-user",
            "circle": ""
          }
        }), _c('el-button', {
          attrs: {
            "type": "danger",
            "icon": "el-icon-switch-button",
            "circle": ""
          },
          on: {
            "click": function click($event) {
              return _vm.logout();
            }
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }