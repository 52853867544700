// eslint-disable-next-line camelcase
import { required, email, min, min_value, confirmed } from 'vee-validate/dist/rules'
import { extend, configure } from 'vee-validate'
import { i18n } from '@/locales/i18n'

configure({
  defaultMessage: (field, values) => {
    // override the field name.
    values._field_ = i18n.t(`fields.${field}`)

    return i18n.t(`validation.${values._rule_}`, values)
  }
})

extend('required', required)
extend('email', email)
extend('min', min)
extend('min_value', min_value)
extend('confirmed', confirmed)
