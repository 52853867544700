<template>
  <div id="app">
    <div v-if="!isLogged">
      <app-login></app-login>
    </div>
    <div v-else>
    <el-container class="hero is-fullheight" sticky-container>
      <el-header>
        <app-header></app-header>
      </el-header>
      <el-container>
        <el-aside class="has-text-left" style="width: unset;">
          <app-aside></app-aside>
        </el-aside>
        <el-main class="p-0">
          <router-view/>
        </el-main>
      </el-container>
    </el-container>
  </div>

  </div>
</template>

<script>
import Header from '@/components/Header'
import Aside from '@/components/Aside'
import Login from '@/views/Login'
import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  components: {
    appHeader: Header,
    appAside: Aside,
    appLogin: Login
  },
  computed: {
    ...mapGetters({
      isLogged: types.GET_LOGGED
    })
  },
  data () {
    return {
    }
  },
  created () {
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.el-notification ::v-deep .el-notification__content {
  text-align: left !important;
}
.el-header {
  padding-left: 0 !important;
}
html,body {
  height: 100%;
}

.h-100 {
  height: 100%;
}

</style>
