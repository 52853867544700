import esLocale from 'element-ui/lib/locale/lang/es'
import es from 'vee-validate/dist/locale/es.json'
export default {
  addCompany: {
    created: {
      title: 'Empresa añadida',
      message: 'La empresa se ha creado correctamente'
    },
    updated: {
      title: 'Empresa actualizada',
      message: 'La empresa se ha actualizado correctamente'
    }
  },
  addCategory: {
    created: {
      title: 'Categoría añadida',
      message: 'La categoría se ha creado correctamente'
    },
    updated: {
      title: 'Categoría actualizada',
      message: 'La categoría se ha actualizado correctamente'
    }
  },
  addQuestion: {
    created: {
      title: 'Pregunta añadida',
      message: 'La pregunta se ha creado correctamente'
    },
    updated: {
      title: 'Pregunta actualizada',
      message: 'La pregunta se ha actualizado correctamente'
    }
  },
  addQuestionnaire: {
    created: {
      title: 'Cuestionario añadido',
      message: 'El cuestionario se ha creado correctamente'
    },
    updated: {
      title: 'Cuestionario actualizado',
      message: 'El cuestionario se ha actualizado correctamente'
    }
  },
  addCUser: {
    created: {
      title: 'Usuario añadida',
      message: 'El usuario se ha creado correctamente'
    },
    updated: {
      title: 'Usuario actualizado',
      message: 'El usuario se ha actualizado correctamente'
    }
  },
  fields: {
    answer: 'respuesta',
    category: 'categoría',
    company: 'empresa',
    confirmPassword: 'confirmar contraseña',
    disposition: 'disposición',
    media: 'archivo multimedia',
    name: 'nombre',
    newPassword: 'nueva contraseña',
    password: 'Contraseña',
    questionType: 'tipo de pregunta',
    role: 'rol',
    section: 'sección',
    selectedQuestions: 'preguntas seccionadas',
    title: 'título',
    user: 'Usuario',
    visible: 'visible'
  },
  question: {
    all: 'Todos',
    hidden: 'Oculto',
    visible: 'Visible'
  },
  validation: es.messages,
  ...esLocale
}
