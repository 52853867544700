var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [!_vm.isLogged ? _c('div', [_c('app-login')], 1) : _c('div', [_c('el-container', {
    staticClass: "hero is-fullheight",
    attrs: {
      "sticky-container": ""
    }
  }, [_c('el-header', [_c('app-header')], 1), _c('el-container', [_c('el-aside', {
    staticClass: "has-text-left",
    staticStyle: {
      "width": "unset"
    }
  }, [_c('app-aside')], 1), _c('el-main', {
    staticClass: "p-0"
  }, [_c('router-view')], 1)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }