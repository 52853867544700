<template>
  <validation-observer ref="observer" tag="div" class="container" v-slot="{ handleSubmit }">
    <div class="column is-half is-offset-one-third" >
      <div class="box is-primary">
        <div class="title has-text-grey">
          <div class="block mt-6">
            <v-icon icon="user" class="is-primary">
            </v-icon>
          </div>
          <div class="block">
            <h1>Login</h1>
          </div>
        </div>
        <div class="block m-6">
          <validation-provider rules="required" name="user">
            <b-field slot-scope="{ errors, valid }"
                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-input value="User"
                       v-model="loginForm.username"
                       icon="user"
                       icon-right="close-circle"
                       icon-right-clickable
                       @icon-right-click="clearIconClick"
                       placeholder="Usuario"
                       @keyup.native.enter="validateLogin"
              >
              </b-input>
            </b-field>
          </validation-provider>
          <validation-provider rules="required" name="password">
            <b-field slot-scope="{ errors, valid }"
                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-input value="Password"
                       type="password"
                       v-model="loginForm.password"
                       icon="key"
                       password-reveal
                       placeholder="Contraseña"
                       @keyup.native.enter="validateLogin"
              >
              </b-input>
            </b-field>
          </validation-provider>
          <b-button class="button is-primary mt-4" @click="handleSubmit(login)"
                   rounded>
            <span>Entrar</span>
          </b-button>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
// import * as types from '@/store/types'
import ErrorServices from '@/services/ErrorServices'
import * as types from '@/store/types'
import { mapGetters } from 'vuex'
export default {
  name: 'SignIn',
  computed: {
    ...mapGetters({
      auth: types.GET_AUTH
    })
  },
  data () {
    return {
      doingLogin: false,
      loginForm: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    clearIconClick () {
      this.loginForm.username = ''
      // TODO Posible notificación
    },
    async getUser () {
      const username = this.auth.idToken['cognito:username']
      await this.axios.get(process.env.VUE_APP_API_GET_USER, {
        params: {
          searchQuery: 'username:' + username,
          page: 0,
          size: 1
        }
      }).then(response => {
        this.$store.dispatch(types.SET_USER, response.data.content[0])
        if (this.$store.getters[types.HAS_ROLE_ADMIN]) {
          this.$router.push({ name: 'Questionnaire' })
        } else {
          this.$router.push({ name: 'DptConsulting' })
        }
      }).catch(err => {
        console.error('No se puede obtener el usuario', err)
      })
    },
    handleErrorCode: function (error) {
      if (error.errorCode === ErrorServices.NEW_PASSWORD_REQUIRED) {
        this.$emit('resetPasswordRequired', {
          username: this.loginForm.username,
          temporaryPassword: this.loginForm.password
        })
      } else if (error.errorCode === ErrorServices.INCORRECT_USERNAME_OR_PASSWORD) {
        this.$notify.error({
          title: 'Error',
          message: 'Nombre de usuario o contraseña incorrectos'
        })
      }
    },
    login () {
      this.doingLogin = true
      this.axios.post(process.env.VUE_APP_API_LOGIN, this.loginForm).then(response => {
        this.$store.dispatch(types.SET_AUTH, response.data)
        this.getUser()
        this.$store.dispatch(types.LOG_IN)
      }).catch(err => {
        if (err.response) {
          this.handleErrorCode(err.response.data)
        }
      }).finally(() => {
        this.doingLogin = false
      })
      /// TODO Gestionar redirección del usuario en función de su rol
      // this.$store.dispatch(types.LOG_IN)
      // this.$router.push({ name: 'Questionnaire' })
    },
    validateLogin () {
      this.$refs.observer.handleSubmit(this.login)
    }
  }
}
</script>

<style lang="scss">

</style>
