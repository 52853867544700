import * as types from '@/store/types'

const state = {
  auth: null,
  isLogged: null,
  user: null
}

const getters = {
  [types.GET_AUTH]: state => {
    return state.auth
  },
  [types.GET_LOGGED]: state => {
    return state.isLogged
  },
  [types.GET_USER]: state => {
    return state.user
  },
  [types.GET_ROLE]: state => {
    return state.user ? state.user.role : null
  },
  [types.HAS_ROLE_ADMIN]: state => {
    return state.user ? state.user.role.id <= 2 : null
  },
  [types.HAS_ROLE_SUPERADMIN]: state => {
    return state.user ? state.user.role.id === 1 : null
  },
  [types.HAS_ROLE_ANNALIST]: state => {
    return state.user ? state.user.role.id === 3 : null
  },
  [types.HAS_ROLE_BELOW_ANNALIST]: state => {
    return state.user ? state.user.role.id >= 3 : null
  }
}

const mutations = {
  [types.LOG_IN]: (state) => {
    state.isLogged = true
  },
  [types.LOG_OUT]: (state) => {
    state.auth = null
    state.user = null
    state.isLogged = false
  },
  [types.SET_USER]: (state, user) => {
    state.user = user
  },
  [types.SET_AUTH]: (state, auth) => {
    state.auth = auth
  }
}

const actions = {
  [types.LOG_IN]: ({ commit }) => {
    commit(types.LOG_IN)
  },
  [types.LOG_OUT]: ({ commit }) => {
    commit(types.LOG_OUT)
  },
  [types.SET_USER]: ({ commit }, userData) => {
    commit(types.SET_USER, userData)
  },
  [types.SET_AUTH]: ({ commit }, auth) => {
    commit(types.SET_AUTH, auth)
  }

}

export default {
  state,
  mutations,
  actions,
  getters
}
