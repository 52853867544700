<template>
  <validation-observer ref="observer" tag="div" class="container" v-slot="{ handleSubmit }">
    <div class="column is-half is-offset-one-third">
      <div class="box is-primary">
        <div class="title has-text-grey">
          <div class="block mt-6">
            <v-icon icon="user" class="is-primary">
            </v-icon>
          </div>
          <div class="block">
            <h1>Resetear Contraseña</h1>
          </div>
          <div class="block">
            <p class="is-size-6 has-text-justified has-text-weight-light">La contraseña con la que ha intentado acceder a la
              aplicación es una
              contraseña
              temporal
              creada
              por
              un administrador. Por motivos de seguridad, se requiere que la cambie por una de su elección. La
              contraseña que elija debe tener al menos 6 caracteres de longitud. Se recomienda el uso de mayúsculas,
            minúsculas, números y símbolos.</p>
          </div>
        </div>
        <div class="block m-6">
          <validation-provider rules="required" name="temporary_password">
            <b-field slot-scope="{ errors, valid }"
                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-input value="Temporary Password"
                       type="password"
                       v-model="resetPasswordForm.temporaryPassword"
                       icon="password"
                       password-reveal
                       placeholder="Contraseña Temporal"
                       disabled
              >
              </b-input>
            </b-field>
          </validation-provider>
          <validation-provider vid="newpassword" rules="required|min:6" name="newPassword">
            <b-field  slot-scope="{ errors, valid }"
                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors" >
              <b-input value="Password"
                       type="password"
                       v-model="resetPasswordForm.password"
                       icon="key"
                       password-reveal
                       placeholder="Nueva Contraseña"
              >
              </b-input>
            </b-field>
          </validation-provider>
          <validation-provider rules="required|confirmed:newpassword" name="confirmPassword">
            <b-field slot-scope="{ errors, valid }"
                     :type="{ 'is-danger': errors[0], 'is-success': valid }"
                     :message="errors">
              <b-input value="Confirm Password"
                       type="password"
                       v-model="resetPasswordForm.confirmPassword"
                       icon="key"
                       password-reveal
                       placeholder="Confirmar Contraseña"
              >
              </b-input>
            </b-field>
          </validation-provider>
          <b-button :disabled="resetingPassword" class="button is-primary mt-4" @click="handleSubmit(resetPassword)" rounded>
            <span>Resetear Contraseña</span>
          </b-button>
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
export default {
  name: 'ResetPassword',
  props: {
    temporaryPassword: {
      type: String,
      required: true
    },
    username: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      resetPasswordForm: {
        username: null,
        password: null,
        confirmPassword: null,
        temporaryPassword: null
      },
      resetingPassword: false
    }
  },
  methods: {
    resetPassword: function () {
      this.resetingPassword = true
      this.axios.put(process.env.VUE_APP_API_RESET_USER_PASSWORD, this.resetPasswordForm).then(() => {
        this.$notify.success({
          title: 'Contraseña actualizada',
          message: 'La contraseña se ha actualizado correctamente'
        })
        this.$emit('passwordReset')
      }).catch(err => {
        console.error('Error al resetear la contraseña', err)
      }).finally(() => {
        this.resetingPassword = false
      })
    }
  },
  created () {
    this.resetPasswordForm.temporaryPassword = this.temporaryPassword
    this.resetPasswordForm.username = this.username
  }
}
</script>

<style scoped>

</style>
