import enLocale from 'element-ui/lib/locale/lang/en'
import en from 'vee-validate/dist/locale/en.json'
export default {
  fields: {
    title: 'title'
  },
  question: {
    all: 'All',
    hidden: 'Hidden',
    visible: 'Visible'
  },
  validation: en.messages,
  ...enLocale
}
